import React from 'react';
import logo from './img/askdev-logo-white.png';
import design from './img/web-design-by-askdev.png';
import dev from './img/web-development-by-askdev.png';
import maintenance from './img/maintenance-by-askdev.png';
import digital from './img/digital-marketing-service-by-askdev.png';
import 'bootstrap-icons/font/bootstrap-icons.css';

export default function Navbar() {
  return (
    <>
        <nav className="navbar navbar-expand-lg navbar-light primary-bg" >
            <div className="container">
                <div className="row w-100">
                    <div className="col-md-3">
                        <a className="navbar-brand" href="/">
                            <img src={logo} className='logo img-fluid' />
                        </a>
                    </div>
                    <div className="col-md-9 float-end">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto py-3 my-lg-0 me-4 navbar-nav-scroll">
                                <li className="nav-item">
                                    <a className="nav-link text-white" aria-current="page" href="#">About</a>
                                </li>
                                
                                <li className="nav-item dropdown">
                                    <a className="text-white nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Service
                                    </a>
                                    <div className='position-relative'>
                                        <div className="row dropdown-menu w-100" aria-labelledby="navbarDropdown">
                                            <div className="col-md-6 mt-3">
                                                <a href='/service/web-design' className='text-decoration-none'>                                                
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <img src={design} alt="" className="img-fluid" />
                                                        </div>
                                                        <div className="col-10">
                                                            <h6 className=''>Web Design</h6>
                                                            <p>Elevate your online presence with our expert web design, landing page design services</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <a href='/service/web-development' className='text-decoration-none'>
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <img src={dev} alt="" className="img-fluid" />
                                                        </div>
                                                        <div className="col-10">
                                                            <h6 className=''>Web Development</h6>
                                                            <p>Unlock the power of cutting-edge web development with us</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-6 mt-3">
                                                <a href='/service/wordpress-development' className='text-decoration-none'>
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <img src={dev} alt="" className="img-fluid" />
                                                        </div>
                                                        <div className="col-10">
                                                            <h6 className=''>Wordpress Development</h6>
                                                            <p>Unlock the power of cutting-edge web development with us</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <a href='/service/e-commerce-development' className='text-decoration-none'>
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <img src={dev} alt="" className="img-fluid" />
                                                        </div>
                                                        <div className="col-10">
                                                            <h6 className=''>E-Commerce Development</h6>
                                                            <p>Unlock the power of cutting-edge web development with us</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-6 mt-3">
                                                <a href='/service/news-portal-development' className='text-decoration-none'>
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <img src={dev} alt="" className="img-fluid" />
                                                        </div>
                                                        <div className="col-10">
                                                            <h6 className=''>News Portal Development</h6>
                                                            <p>Unlock the power of cutting-edge web development with us</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-6 mt-3">
                                                <a href='/service/maintenance' className='text-decoration-none'>
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <img src={maintenance} alt="" className="img-fluid" />
                                                        </div>
                                                        <div className="col-10">
                                                            <h6 className=''>Maintenance</h6>
                                                            <p>Ensure peak performance and security with our comprehensive IT maintenance services.</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-6 mt-3">
                                                <a href='/service/digital-marketing' className='text-decoration-none'>
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <img src={digital} alt="" className="img-fluid" />
                                                        </div>
                                                        <div className="col-10">
                                                            <h6 className=''>Digital Marketing</h6>
                                                            <p>Maximize your online impact with our results-driven digital marketing solutions</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            
                                        </div>  
                                    </div>
                                                                      
                                </li>


                                <li className="nav-item dropdown">
                                    <a className="text-white nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Industries
                                    </a>
                                    <div className="row dropdown-menu w-100" aria-labelledby="navbarDropdown">
                                        <div className="col-md-6 my-2">
                                            <a href='/service/information-technology' className='text-decoration-none'>
                                                <div className="row">
                                                    <div className="col-2">
                                                        <img src={design} alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-10">
                                                        <h6 className='mt-1'>Information Technology</h6>                                                    
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <a href='/service/health-and-medical' className='text-decoration-none'>
                                                <div className="row">
                                                    <div className="col-2">
                                                        <img src={dev} alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-10">
                                                        <h6 className='mt-1'>Healthcare & Medical</h6>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-md-6 my-2">
                                            <a href='/service/automobile' className='text-decoration-none'>
                                                <div className="row">
                                                    <div className="col-2">
                                                        <img src={maintenance} alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-10">
                                                        <h6 className='mt-1'>Automobile</h6>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-md-6 my-2">
                                            <a href='/service/education' className='text-decoration-none'>
                                                <div className="row">
                                                    <div className="col-2">
                                                        <img src={maintenance} alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-10">
                                                        <h6 className=''>Education</h6>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-md-6 my-2">
                                            <a href='/service/travel-and-tourism' className='text-decoration-none'>
                                                <div className="row">
                                                    <div className="col-2">
                                                        <img src={digital} alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-10">
                                                        <h6 className='mt-1'>Travel & Tourishm</h6>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <a href='/service/food-and-beverages' className='text-decoration-none'>
                                                <div className="row">
                                                    <div className="col-2">
                                                        <img src={digital} alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-10">
                                                        <h6 className='mt-1'>Food & Beverages</h6>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-md-6 my-2">
                                            <a href='/service/e-commerce' className='text-decoration-none'>
                                                <div className="row">
                                                    <div className="col-2">
                                                        <img src={digital} alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-10">
                                                        <h6 className='mt-1'>E-Commerce</h6>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-md-6 my-2">
                                            <a href='/service/affialiate-marketing' className='text-decoration-none'>
                                                <div className="row">
                                                    <div className="col-2">
                                                        <img src={digital} alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-10">
                                                        <h6 className='mt-1'>Affialiate Marketing</h6>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        
                                    </div>                                    
                                </li>

                                <li className="nav-item dropdown">
                                    <a className="text-white nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Technology
                                    </a>
                                    <div className="row dropdown-menu w-100" aria-labelledby="navbarDropdown">
                                        
                                        <div className="col-md-4 mt-2">
                                            <div className="row align-items-center ps-3">
                                                <div className="col-md-12">
                                                    <h5>Frontend</h5>
                                                </div>                                                
                                                <div className="col-10">
                                                    <h6 className='border-start border-2 ps-2 mt-3 mb-2'><a className='text-decoration-none' href='/service/react-js-development'>React JS</a></h6>                                                    
                                                </div>
                                                                                           
                                                <div className="col-10">
                                                    <h6 className='border-start border-2 ps-2 my-2'><a className='text-decoration-none' href='/service/html5-development'>HTML 5</a></h6>                                                    
                                                </div>                                           
                                                <div className="col-10">
                                                    <h6 className='border-start border-2 ps-2 my-2'><a className='text-decoration-none' href="/service/css3-development">CSS3</a></h6>                                                    
                                                </div>                                            
                                                <div className="col-10">
                                                    <h6 className='border-start border-2 ps-2 my-2'><a className='text-decoration-none' href='/service/javascript-development'>JavaScript</a></h6>                                                    
                                                </div>
                                                <div className="col-10">
                                                    <h6 className='border-start border-2 ps-2 my-2'><a className='text-decoration-none' href='/service/jquery-development'>JQuery</a></h6>                                                    
                                                </div>
                                            </div>
                                        </div>

                                        

                                        <div className="col-md-4 mt-2">
                                            <div className="row align-items-center ps-3">
                                                <div className="col-md-12">
                                                    <h5>Backend</h5>
                                                </div>                                                
                                                <div className="col-10">
                                                    <h6 className='border-start border-2 ps-2 mt-3 mb-2'>
                                                        <a className='text-decoration-none' href='/service/php-development'>PHP</a>
                                                    </h6>                                                    
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <h5>Framework</h5>
                                                </div>
                                                <div className="col-10">
                                                    <h6 className='border-start border-2 ps-2 mt-3 mb-2'>
                                                        <a className='text-decoration-none' href='/service/codeignitor'>CodeIgnitor</a>
                                                    </h6>                                                    
                                                </div>
                                                <div className="col-10">
                                                    <h6 className='border-start border-2 ps-2 mt-3 mb-2'>
                                                        <a className='text-decoration-none' href='/service/laravel-development'>Laravel</a>
                                                    </h6>                                                    
                                                </div>                                                                                           
                                                
                                            </div>
                                        </div>

                                        <div className="col-md-4 mt-2">
                                            <div className="row align-items-center ps-3">
                                                <div className="col-md-12">
                                                    <h5>Database</h5>
                                                </div>                                                
                                                <div className="col-10">
                                                    <h6 className='border-start border-2 ps-2 mt-3 mb-2'>
                                                        <a className='text-decoration-none' href='/service/sql-server-database-development'>SQL Server</a>
                                                    </h6>                                                    
                                                </div>
                                                <div className="col-10">
                                                    <h6 className='border-start border-2 ps-2 mt-3 mb-2'>
                                                        <a className='text-decoration-none' href='/service/mysql-database-development'>MySQL</a>
                                                    </h6>                                                    
                                                </div>
                                                <div className="col-10">
                                                    <h6 className='border-start border-2 ps-2 mt-3 mb-2'>
                                                        <a className='text-decoration-none' href='/service/mangodb-development'>MangoDB</a>
                                                    </h6>                                                    
                                                </div>                                                                                                                                      
                                                
                                            </div>
                                        </div>
                                    </div>                                    
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" aria-current="page" href="work">Work</a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link text-white" aria-current="page" href="contact">Contact</a>
                                </li>

                                <li className="nav-item">
                                    <a className="border  rounded-pill ms-4 px-3 nav-link text-white" aria-current="page" href="discuss-project">Discuss Project</a>
                                </li>
                                
                            </ul>
                        
                        </div>
                    </div>
                </div>
                

                
            </div>
            </nav>
    </>
  )
}
