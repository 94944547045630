import React from 'react'

import level from '../img/level.png'


export default function FirstSec() {
  return (
    <>
    <section className='primary-bg'>
        <div className="container">
            <div className="row py-5">
                <div className="col-md-10 py-5">
                    <h1 className='text-white'>End-to-end digital solutions that <span>Boost your business</span></h1>
                    <h2 className='text-white h1'>to next level <img src={level} className='img-fluid mt--3' /></h2>
                    <p className='text-white fs-5'>Building digital products from idea of innovation to <strong>design, development to testing</strong> and marketing to post launch support.</p>
                    <div>
                        <a href='discuss-project'><button className='btn shadow-lg border danger btn-light rounded me-3 px-4'>Tell Us About Your Project <i className="bi bi-arrow-right"></i></button></a>
                        
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </section>

    
    </>
  )
}
