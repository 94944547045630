import React from 'react';
import partner1 from '../img/partner/ns_softech.jpg';
import partner2 from '../img/partner/logo-clickbyseo.jpg';
import partner3 from '../img/partner/Cygen-logo.png';
import partner4 from '../img/partner/lookupit-solutions.png';
import partner5 from '../img/partner/nexonweb.png';
import partner6 from '../img/partner/kbk-group.png';
import partner7 from '../img/partner/sura-space.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow slick-next" onClick={onClick}>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  };
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow slick-prev" onClick={onClick}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
  };
const TrustedBy = () => {
   
    const settings = {
      dots: false,
     
      infinite: true,
      speed: 500,
      slidesToShow: 5, // Number of items to show at a time
      slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  
    return (
        <>
        <section className='py-5'>
            <div className="container">
                <div className="row justify-content-center pb-4">
                    <div className="col-md-10 text-center">
                        <h2 className='text-center'>Trusted By</h2>
                    </div>
                </div>            
            </div>
            <div className="container">
                <div className="row">
                    <Slider {...settings}>
                        
                        <div className='px-2'>
                            <div className=' p-3'>
                                <img src={partner1} className='img-fluid partner' alt='fullStack' />                                
                            </div>
                        </div>
                        <div className='px-2'>
                            <div className=' p-3'>
                                <img src={partner2} className='img-fluid partner' alt='fullStack' />                                
                            </div>
                        </div>
                        <div className='px-2'>
                            <div className=' p-3'>
                                <img src={partner3} className='img-fluid partner' alt='fullStack' />                                
                            </div>
                        </div>
                        <div className='px-2'>
                            <div className=' p-3'>
                                <img src={partner4} className='img-fluid partner' alt='fullStack' />                                
                            </div>
                        </div>
                        <div className='px-2'>
                            <div className=' p-3'>
                                <img src={partner5} className='img-fluid partner' alt='fullStack' />                                
                            </div>
                        </div> 
                        <div className='px-2'>
                            <div className=' p-3'>
                                <img src={partner6} className='img-fluid partner' alt='fullStack' />                                
                            </div>
                        </div> 
                        <div className='px-2'>
                            <div className=' p-3'>
                                <img src={partner7} className='img-fluid partner bg-dark' alt='fullStack' />                                
                            </div>
                        </div>                        
                    </Slider>
                </div>
            </div>
        </section>
        
        
      </>
    );
  };
  
  export default TrustedBy;
  