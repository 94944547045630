import React from 'react'

export default function Services() {
  return (
    <section className='py-5'> 
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 text-center pb-4">                    
                    <h2>Services offered </h2>
                </div>
            
            
                <div className="col-md-10">
                    
                    <div class="d-flex align-items-start" id='Servicess'>
                        <div class="nav position-relative flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <div className='position-relative'>
                                <button class="nav-link active" id="Application-Development-tab" data-bs-toggle="pill" data-bs-target="#Application-Development" type="button" role="tab" aria-controls="Application-Development" aria-selected="true">Application Development</button>
                            </div>
                            <div className='position-relative'>
                                <button class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Software Development</button>
                            </div>
                            <div className='position-relative'>
                                <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Digital Marketing</button>
                            </div>
                        </div>
                        <div class="tab-content" id="v-pills-tabContent">
                            <div class="tab-pane fade show active" id="Application-Development" role="tabpanel" aria-labelledby="Application-Development-tab">
                                <div className='serviceBG px-5 py-5'>
                                    <h4 className='text-white'>Application Development</h4>
                                    <p className='text-white'>
                                        Achieve enhanced flexibility, agility, and growth in next-generation application development. Leverage our extensive proficiency in Application Modernization, Cloud Application, and Application Management to unleash the potential of emerging technologies.
                                    </p>
                                    <ul className='serviceUL'>
                                        <li className='text-white'>Custom Web Application</li>
                                        <li className='text-white'>Custom Application Development</li>
                                        <li className='text-white'>Application Management</li>
                                        <li className='text-white'>Application Maintenance</li>
                                        <li className='text-white'>Web Portal</li>
                                        <li className='text-white'>Landing Page For Ads</li>    
                                    </ul>

                                </div>
                            </div>
                            <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                            
                                <div className='serviceBG px-5 py-5'>
                                    <h4 className='text-white'>Software Development</h4>
                                    <p className='text-white'>
                                    Empower your software development with advanced capabilities, ensuring greater flexibility, agility, and substantial growth. Harness our comprehensive expertise in Software Development, covering Application Modernization, Cloud Integration, and Efficient Application Management
                                    </p>
                                    <ul className='serviceUL'>
                                        <li className='text-white'>Custom Software Application</li>
                                        <li className='text-white'>Custom Software Development</li>
                                        <li className='text-white'>Dedicated Development Team</li>
                                        <li className='text-white'>Software Maintenance & Support</li>
                                         
                                    </ul>
                                </div>
                            
                            </div>
                            <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                <div className='serviceBG px-5 py-5'>
                                    <h4 className='text-white'>Digital Marketing</h4>
                                    <p className='text-white'>
                                        Explore the power of strategic campaigns, data-driven insights, and innovative solutions in areas such as Social Media Management, Search Engine Optimization, and Content Marketing. Amplify your online footprint with our proven expertise, driving measurable results and maximizing your digital potential.
                                    </p>
                                    <ul className='serviceUL'>
                                        <li className='text-white'>Google Ads</li>
                                        <li className='text-white'>Facebook Ads</li>
                                        <li className='text-white'>Social Media Marketing</li>
                                        <li className='text-white'>Search Engine Optimization</li>
                                        <li className='text-white'>Content Marketing</li>
                                        <li className='text-white'>Influencer Marketing</li>
                                        <li className='text-white'>Affliate Marketing</li>
                                         
                                    </ul>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">...</div>
                        </div>
                    </div>                
                </div> 
            </div>           
        </div>
    </section>
  )
}
