import './App.css';
import FirstSec from './components/Home/FirstSec';
import ResponsiveSlider from './components/Home/ResponsiveSlider';
import Services from './components/Home/Services';
import TechnologyPlatform from './components/Home/TechnologyPlatform';
import TrustedBy from './components/Home/TrustedBy';
import Navbar from './components/Navbar';

function App() {
  return (
    <>    
      <Navbar></Navbar>
      <FirstSec/>
      <ResponsiveSlider/>
      <TrustedBy />
      <Services/>
      <TechnologyPlatform/>
    </>
  );
}

export default App;
