import React from 'react';
import reactF from '../img/technology/react.png';
import htmlF from '../img/technology/html.png';
import cssF from '../img/technology/css3.png';
import jsF from '../img/technology/js.png';
import jqueryF from '../img/technology/jquery.png';
import phpF from '../img/technology/core-php.png';
import codeignitorF from '../img/technology/codeignitor.png';
import laravelF from '../img/technology/laravel.png';
import sqlD from '../img/technology/sql-server.png';
import mysqlD from '../img/technology/mySql.png';
import mangoD from '../img/technology/mongodb.png';
import wordpressD from '../img/technology/wordpress.png';
import woocommerceD from '../img/technology/woo-commerce.png';


export default function TechnologyPlatform() {
  return (
    <>
        <section className='py-5'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <h3 className='text-center pb-4'>Technologies and Platforms We Use</h3>
                    </div>
                    <div className="col-md-7">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="frontend-tab" data-bs-toggle="tab" data-bs-target="#frontend" type="button" role="tab" aria-controls="frontend" aria-selected="true">Frontend</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="backend-tab" data-bs-toggle="tab" data-bs-target="#backend" type="button" role="tab" aria-controls="backend" aria-selected="false">Backend</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="database-tab" data-bs-toggle="tab" data-bs-target="#database" type="button" role="tab" aria-controls="database" aria-selected="false">Database</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="framework-tab" data-bs-toggle="tab" data-bs-target="#framework" type="button" role="tab" aria-controls="framework" aria-selected="false">Framework</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="cms-tab" data-bs-toggle="tab" data-bs-target="#cms" type="button" role="tab" aria-controls="cms" aria-selected="false">CMS</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="ecommerce-tab" data-bs-toggle="tab" data-bs-target="#ecommerce" type="button" role="tab" aria-controls="ecommerce" aria-selected="false">eCommerce</button>
                            </li>
                        </ul>   
                    </div>
                    <div className='col-md-10'>                     
                    
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="frontend" role="tabpanel" aria-labelledby="frontend-tab">
                                <div className="row justify-content-center pt-3">
                                    <div className='col-md-2 col-6 text-center'>
                                        <img src={reactF} className='img-fluid' alt="" />
                                        <p>React</p>
                                    </div>
                                    <div className='col-md-2 col-6 text-center'>
                                        <img src={htmlF} className='img-fluid' alt="" />
                                        <p>HTML5</p>
                                    </div>
                                    <div className='col-md-2 col-6 text-center'>
                                        <img src={cssF} className='img-fluid' alt="" />
                                        <p>CSS3</p>
                                    </div>
                                    <div className='col-md-2 col-6 text-center'>
                                        <img src={jsF} className='img-fluid' alt="" />
                                        <p>JavaScript</p>
                                    </div>
                                    <div className='col-md-2 col-6 text-center'>
                                        <img src={jqueryF} className='img-fluid' alt="" />
                                        <p>jQuery</p>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="tab-pane fade" id="backend" role="tabpanel" aria-labelledby="backend-tab">
                                <div className="row justify-content-center pt-3">
                                    <div className='col-md-2 col-6 text-center'>
                                        <img src={phpF} className='img-fluid' alt="" />
                                        <p>PHP</p>
                                    </div>
                                    <div className='col-md-2 col-6 text-center'>
                                        <img src={codeignitorF} className='img-fluid' alt="" />
                                        <p>Codeignitor</p>
                                    </div>
                                    <div className='col-md-2 col-6 text-center'>
                                        <img src={laravelF} className='img-fluid' alt="" />
                                        <p>Laravel</p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="tab-pane fade" id="database" role="tabpanel" aria-labelledby="database-tab">
                                <div className="row justify-content-center pt-3">
                                    <div className='col-md-2 col-6 text-center'>
                                        <img src={sqlD} className='img-fluid' alt="" />
                                        <p>SQL</p>
                                    </div>
                                    <div className='col-md-2 col-6 text-center'>
                                        <img src={mysqlD} className='img-fluid' alt="" />
                                        <p>MySQL</p>
                                    </div>
                                    <div className='col-md-2 col-6 text-center'>
                                        <img src={mangoD} className='img-fluid' alt="" />
                                        <p>MangoDB</p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="tab-pane fade" id="framework" role="tabpanel" aria-labelledby="framework-tab">
                                <div className="row justify-content-center pt-3">
                                    <div className='col-md-2 col-6 text-center'>
                                        <img src={reactF} className='img-fluid' alt="" />
                                        <p>React</p>
                                    </div>
                                    <div className='col-md-2 col-6 text-center'>
                                        <img src={codeignitorF} className='img-fluid' alt="" />
                                        <p>Codeignitor</p>
                                    </div>
                                    <div className='col-md-2 col-6 text-center'>
                                        <img src={laravelF} className='img-fluid' alt="" />
                                        <p>Laravel</p>
                                    </div>                                   
                                </div>
                            </div>
                            <div class="tab-pane fade" id="cms" role="tabpanel" aria-labelledby="cms-tab">
                                <div className="row justify-content-center pt-3">
                                    <div className='col-md-2 col-6 text-center'>
                                        <img src={wordpressD} className='img-fluid' alt="" />
                                        <p>WordPress</p>
                                    </div>                                   
                                                                       
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ecommerce" role="tabpanel" aria-labelledby="ecommerce-tab">
                                <div className="row justify-content-center pt-3">
                                    <div className='col-md-2 col-6 text-center'>
                                        <img src={woocommerceD} className='img-fluid' alt="" />
                                        <p>Woocommerce</p>
                                    </div>     
                                    <div className='col-md-2 col-6 text-center'>
                                        <img src={codeignitorF} className='img-fluid' alt="" />
                                        <p>Codeignitor</p>
                                    </div>                                                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
