import React from 'react';
import fullStack from '../img/full-stack-development-services.png'
import customSoft from '../img/custom-software-development.png'
import webApp from '../img/web-app-development.png'
import healthcare from '../img/healthcare.png'
import eLearn from '../img/e-learning-dev.png'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow slick-next" onClick={onClick}>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  };
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow slick-prev" onClick={onClick}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
  };
const ResponsiveSlider = () => {
   
    const settings = {
      dots: false,
     
      infinite: true,
      speed: 500,
      slidesToShow: 3, // Number of items to show at a time
      slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  
    return (
        <>
        <section className='py-5'>
            <div className="container">
                <div className="row justify-content-center pb-4">
                    <div className="col-md-10 text-center">
                        <h2 className='text-center'>Your Comprehensive Offshore Outsourcing Ally for Driving Growth</h2>
                        <p>Acquire our exceptional capabilities to build Software, responsive browser supported Web Development, e-commerce development and digital markrting service and new growth opportunities </p>
                    </div>
                </div>            
            </div>
            <div className="container">
                <div className="row">
                    <Slider {...settings}>
                        
                        <div className='px-2'>
                            <div className='primary-light p-3'>
                                <img src={fullStack} className='img-fluid' alt='fullStack' />
                                <p className="service_title mt-3">Full Stack Development Services</p>
                                <p className='service_desc'>Deliver uninterrupted app experiences designed to meet your business needs, regardless of scale, utilizing the most fitting tools and frameworks</p>
                                <p className='fw-bold service_but'>Add Value to Your Business</p>
                            </div>
                        </div>
                        <div className='px-2'>
                            <div className='border p-3'>
                                <img src={customSoft} className='img-fluid' alt='fullStack' />
                                <p className="service_title mt-3">Custom Software Development Services</p>
                                <p className='service_desc'>Deliver uninterrupted app experiences designed to meet your business needs, regardless of scale, utilizing the most fitting tools and frameworks</p>
                                <p className='fw-bold service_but'>Meet Unique Demand</p>
                            </div>
                        </div>
                        <div className='px-2'>
                            <div className='border p-3'>
                                <img src={webApp} className='img-fluid' alt='fullStack' />
                                <p className="service_title mt-3">Web Application Development</p>
                                <p className='service_desc'>Unlock the potential of a streamlined online presence. Our skilled team can create highly responsive solutions using the latest frameworks.</p>
                                <p className='fw-bold service_but'>Reign Over the World</p>
                            </div>
                        </div>
                        <div className='px-2'>
                            <div className='border p-3'>
                                <img src={healthcare} className='img-fluid healthcare' alt='fullStack' />
                                <p className="service_title mt-3">Healthcare Software Development</p>
                                <p className='service_desc'>Unlock the potential of a streamlined online presence. Our skilled team can create highly responsive solutions using the latest frameworks.</p>
                                <p className='fw-bold service_but'>Customize Healthcare Goals</p>
                            </div>
                        </div>
                        <div className='px-2'>
                            <div className='border p-3'>
                                <img src={eLearn} className='img-fluid elearn' alt='fullStack' />
                                <p className="service_title mt-3">E-Learning Development Development</p>
                                <p className='service_desc'>Unlock the potential of a streamlined online presence. Our skilled team can create highly responsive solutions using the latest frameworks.</p>
                                <p className='fw-bold service_but'>Empower New Way of Learning</p>
                            </div>
                        </div>                        
                    </Slider>
                </div>
            </div>
        </section>
        
        
      </>
    );
  };
  
  export default ResponsiveSlider;
  